import { FaAward, FaDesktop, FaHeadset, FaLinkedin, FaMoneyBill, FaPersonBooth, FaSearch, FaShippingFast, FaSuperpowers, FaTrain, FaWeebly } from "react-icons/fa";

export const AllSections = [
  {
    header: "Services",
    description: "We Are Offering All Kinds of IT Solutions & Services",
    items: [
      {
        icon: <FaDesktop size={52} />,
        header: "Software Development",
        description: "Contact us for developing software for your customized need",
      },
      {
        icon: <FaWeebly size={52} />,
        header: "Web Development",
        description: "We are pioneer in development & deployment of website and web applications",
      },
      {
        icon: <FaHeadset size={52} />,
        header: "Technical Support",
        description: "We are experience provider for technical support. We help you build the quality software by guding on every step.",
      },
      {
        icon: <FaAward size={52} />,
        header: "Training & Development",
        description: "We do provide training & development to ensure that your team is able to deliver quality products. We have expertise in providing training for computer fundamentals",
      },
      {
        icon: <FaSearch size={52} />,
        header: "Analytics Solutions",
        description: "We  help you with creating analytics and business decision making",
      },
    ],
  },
  {
    header: "Success",
    description: "Why Choose Us?",
    items: [
      {
        icon: <FaLinkedin size={52} />,
        header: "Technical Expertise",
        description: "We have a pool of technical experts who ensures your success",
      },
      {
        icon: <FaMoneyBill size={52} />,
        header: "Cost Effectiveness",
        description: "We provide very cost effective services so it does not becomes heavy on your pocket",
      },
      {
        icon: <FaShippingFast size={52} />,
        header: "Agile Development",
        description: "We follow Agile development principles to ensure that you get fast delivery",
      },
    ],
  },
];
