import { Link } from "react-router-dom";
import styled from "styled-components";

const NotFoundPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
`;

const NotFoundPage = () => {
  return (
    <NotFoundPageContainer className="NotFoundPage">
      <h3>Oh snap! The page you are looking for was never born !!</h3>
      <div>
        <Link to="/">Go to the home page</Link>
      </div>
    </NotFoundPageContainer>
  );
};

export { NotFoundPage };
