import { FaSearchLocation, FaMailBulk, FaWhatsapp } from "react-icons/fa";
import { ContactHeader } from "../..";
import "./index.scss";

import Logo from "../../../core/favicon/favicon-32x32.png";

const contactItems = [
    {
        icon: <FaSearchLocation className="icon" size={26} />,
        header: "Address",
        text: "Hyderabad, India"
    },
    {
        icon: <FaMailBulk className="icon" size={26} />,
        header: "E-mail",
        text: <a href="mailto:tech.centoreal@outlook.com">tech.centoreal@outlook.com</a>
    },
    {
        icon: <FaWhatsapp className="icon" size={26} />,
        header: "WhatsApp",
        text: "+91-8500840627"
    }
];

const SiteContactHeader = () => {
  return (
    <header>
      <div className="SiteHeader">
          <div className="log-container">
              <img className="logo" src={Logo} />
              <span className="company-name">CentoReal Technologies</span>
          </div>
          <div className="contact-container">
              <ContactHeader contacts={contactItems} />
          </div>
      </div>
    </header>
  );
};

export { SiteContactHeader };
