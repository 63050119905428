import { Outlet } from "react-router-dom";
import { SiteContactHeader, SiteMenuHeader } from "..";
import { Footer } from "../..";

import { MenuItem } from "../../../core";

type SiteLayoutProps = {
  menus: MenuItem[];
};

const SiteLayout = ({ menus }: SiteLayoutProps) => {
  return (
    <div className="SiteLayout">
      <SiteContactHeader />
      <SiteMenuHeader menus={menus} />
      <Outlet />
      <Footer />
    </div>
  );
};

export { SiteLayout };
