import {
  ContentSectionsContainer,
  ItemContainer,
  ItemHeader,
  ItemIcon,
  ItemsContainer,
  ItemText,
  Section,
  SectionDescription,
  SectionHeader,
} from "./styles";
import { AllSections } from "./data";

const ContentSections = () => {
  return (
    <ContentSectionsContainer>
      {AllSections.map((section) => (
        <Section key={section.header}>
          <SectionHeader>{section.header}</SectionHeader>
          <SectionDescription>{section.description}</SectionDescription>
          <ItemsContainer>
            {section.items.map((item) => (
              <ItemContainer key={item.header}>
                <ItemIcon>{item.icon}</ItemIcon>
                <ItemHeader>{item.header}</ItemHeader>
                <ItemText>{item.description}</ItemText>
              </ItemContainer>
            ))}
          </ItemsContainer>
        </Section>
      ))}
    </ContentSectionsContainer>
  );
};

export { ContentSections };
