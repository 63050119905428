import styled from "styled-components";

export const ContentSectionsContainer = styled.div`

`;

export const Section = styled.div`
    padding: 20px 5px 20px 5px;
    background-color: #f6f7f9;
`;

export const SectionHeader  = styled.div`
    text-transform: uppercase;
    color: #03228f;
    line-height: 28px;
    font-size: 14px;
    font-weight: 400;
`;

export const SectionDescription = styled.div`
    font-size: 36px;
    font-weight: 700;
    line-height: 1.4;
    color: #0a0a0a;
    max-width: 500px;
    margin: 0 auto;
    padding: 5px 5px 35px 5px;
`;

export const ItemsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
`;

export const ItemContainer = styled.div`
    flex: 1 1;
    margin: 15px;
    padding: 30px;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
`;

export const ItemIcon = styled.div`
    padding: 5px;
`;

export const ItemHeader = styled.h3``;

export const ItemDescription = styled.div``;

export const ItemText = styled.div``;