export enum IDifficultyLevel {
    Unknown = 0,
    Entry = 1,
    Intermediate = 2,
    Advanced = 3
};

export interface IQuestion {
    q: string;
    a: string;
    level: IDifficultyLevel;
    category: string;
    topics: string[];
}