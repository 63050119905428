import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { MenuItem } from "../../../core";

import "./index.scss";

const SocialItem = styled.div`
  display: flex;
`;


type SiteMenuHeaderProps = {
  menus: MenuItem[];
};

const socialItems = [
  {
    id: "facebook",
    icon: <FaFacebook size={20} className="social-icon" />,
    href: "https://www.facebook.com/CentoReal-Technologies-107616125196691"
  },
  {
    id: "twitter",
    icon: <FaTwitter size={20} className="social-icon" />,
    href: "https://twitter.com/CentoReal"
  },
  {
    id: "linkedin",
    icon: <FaLinkedin size={20} className="social-icon" />,
    href: "https://www.linkedin.com/company/centoreal-technologies/"
  }
];

const SiteMenuHeader = ({ menus }: SiteMenuHeaderProps) => {
  return (
    <div className="SiteMenuHeader">
      <div className="menu-section">
        <nav>
          <ul>
            {menus.map((menu: MenuItem) => (
              <li key={menu.id}>
                <Link to={menu.route}>{menu.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="social-section">
        <SocialItem className="social-icons">
          {
            socialItems.map((item) => (
              <a key={item.id} href={item.href} target="_blank">
                {item.icon}
              </a>
            ))
          }
        </SocialItem>
      </div>
    </div>
  );
};

export { SiteMenuHeader };
