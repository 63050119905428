import styled from "styled-components";
import { IDifficultyLevel } from "../../../core";

type DifficultyLevelProps = {
  level: IDifficultyLevel;
};

const DifficultyLevelContainer = styled.div`
  padding: 3px 5px 3px 5px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 0.8em;
  color: #000;
`;

const DifficultyLevel = ({ level }: DifficultyLevelProps) => {
  const getDifficultyLabel = (level: IDifficultyLevel) => {
    return level === IDifficultyLevel.Unknown
      ? "Unknown"
      : level === IDifficultyLevel.Entry
      ? "Entry"
      : level === IDifficultyLevel.Intermediate
      ? "Intermediate"
      : "Advanced";
  };
  const style = {
    backgroundColor:
      level === IDifficultyLevel.Unknown
        ? "lightgrey"
        : level === IDifficultyLevel.Entry
        ? "#dcedc8"
        : level === IDifficultyLevel.Intermediate
        ? "#ffecb3"
        : "#ffcdd2",
  };
  return (
    <DifficultyLevelContainer className="DifficultyLevel" style={style}>
      {getDifficultyLabel(level)}
    </DifficultyLevelContainer>
  );
};

export { DifficultyLevel };
