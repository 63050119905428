import styled from "styled-components";

const FooterContainer = styled.div`
    font-size: 12px;
    color: gray;
    padding: 15px 5px 15px 5px;
`;

const Footer = () => {
    return (
        <footer className="Footer">
            <FooterContainer>
                &#169; 2022 All Rights Reserved. Developed By CentoReal Technologies
            </FooterContainer>
        </footer>
    );
};

export {
    Footer
};