import "./index.scss";

type IconTextProps = {
    icon: any;
    header: string;
    text: string;
    style?: any;
};

const IconText = ({icon, header, text, style} : IconTextProps) => {
    return (
        <div className="IconText" style={style}>
            {icon}
            <div className="text">
                <span className="header">{header}</span>
                <span>{text}</span>
            </div>
        </div>
    );
};

export {
    IconText
};