import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MenuItem } from "./modules/core";
import { NotFoundPage, SiteLayout } from "./modules/shared";

import "./App.scss";

const HomePage = React.lazy(() =>
  import("./pages/home").then(({ HomePage }: any) => ({ default: HomePage }))
);
const InterviewQuestionAnswersPage = React.lazy(() =>
  import("./pages/interview-questions-answers").then(({ InterviewQuestionsAnswersPage }: any) => ({ default: InterviewQuestionsAnswersPage }))
);
const QuizPage = React.lazy(() =>
  import("./pages/quiz").then(({ QuizPage }: any) => ({ default: QuizPage }))
);

const menuItems: MenuItem[] = [
  {
    id: "home",
    label: "Home",
    route: "/",
  },
  {
    id: "interview-question-answers",
    label: "Interview",
    route: "/interview-question-answers",
  },
  {
    id: "quiz",
    label: "Quiz",
    route: "/quiz",
  }
];

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<SiteLayout menus={menuItems} />}>
              <Route index element={<HomePage />} />
              <Route path="interview-question-answers" element={<InterviewQuestionAnswersPage />} />
              <Route path="quiz" element={<QuizPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}
