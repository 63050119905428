import styled from "styled-components";

import { IconText } from "..";

const ContactList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ContactItem = styled.div`
  display: inline;
`;

type ContactHeaderProps = {
  contacts: any[];
};

const ContactHeader = ({ contacts }: ContactHeaderProps) => {
  return (
    <div className="ContactHeader">
      <ContactList>
        {contacts.map((contact: any) => (
          <ContactItem key={contact.header}>
            <IconText
              icon={contact.icon}
              header={contact.header}
              text={contact.text}
              style={{ borderRight: "1px solid #eee" }}
            />
          </ContactItem>
        ))}
      </ContactList>
    </div>
  );
};

export { ContactHeader };
