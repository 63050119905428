import { IconType } from "react-icons";
import styled from "styled-components";

type VerticalIconTextProps = {
    icon: IconType,
    header: string,
    text: string,
    className?: string
};

const VerticalIconTextContainer = styled.div`
  flex: 1;
  margin: 15px;
  padding: 30px;
  border-radius: 6px;
  background-color: #fff;

// display: flex;
// flex-direction: column;
`;

const IconContainer = styled.div`
  padding: "5px"
`;

const Header = styled.h3`
`;

const Text = styled.span`
`;

const VerticalIconText = ({icon, header, text} : VerticalIconTextProps) => {
  return (
    <VerticalIconTextContainer className="VerticalIconText">
      <IconContainer>
        {icon}
      </IconContainer>
      <Header>{header}</Header>
      <Text>{text}</Text>
    </VerticalIconTextContainer>
  );
};

export { VerticalIconText };
